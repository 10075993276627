import React, { SyntheticEvent } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _get from 'lodash/get';
import {
  AvailabilityTileSize,
  ProviderAvailability,
  Slot,
  getProviderImageUrl,
  AppointmentInfo,
  PurposeOptionsByRelationship
} from '@kyruus/provider-components';

import Theme from '@kyruus/ui-theme';
import Link from '@kyruus/link';
import { PMCConfig } from '@kyruus/types';
import { FormattedMessage } from '@kyruus/intl';
import { formatAvailabilityForTimeTiles } from '@kyruus/availability-tiles';

import { AppointmentSlot, ExtendedProvider, Log } from 'Src/types';
import { getProviderSpecialties } from 'Src/utils/provider';
import { SideSheet } from '../../../search-v9/SideSheet';
import {
  Header,
  ProviderSummary,
  ProviderImage,
  ProviderName,
  ProviderSpecialties
} from './styles';
import { messages } from './messages';
import {
  AVAILABILITY_DRAWER,
  TRACKING_EVENTS,
  MAX_AVAILABILITIES_TO_DISPLAY
} from '../constants';
import { useAvailability } from './useAvailbility';
import { Loading } from './Loading';

interface AvailabilityDrawerProps {
  bookOnlineUrl: string;
  config: PMCConfig;
  provider: ExtendedProvider;
  appointmentInfo: AppointmentInfo;
  onClose: (providerId: number, reason: string) => void;
  log: Log;
  onViewMoreClick: (providerId: number, href: string) => void;
  fetchProviderAvailability: (
    providerIds: number,
    appointmentInfo: AppointmentInfo
  ) => Promise<{ slots: AppointmentSlot[]; error?: boolean }>;
  selectedDate: string;
  onTimeSlotsClick: (
    date: string | undefined,
    time: string | undefined,
    providerId: number,
    href: string,
    unformattedDate: string,
    provider: ExtendedProvider
  ) => void;
  purposeOptionsByProviderId: Record<number, PurposeOptionsByRelationship>;
  availabilityError: string;
  purposesLoading: boolean;
  fetchPurposesByProviderId: (providerId: number, log: Log) => Promise<void>;
}

export const AvailabilityDrawer = ({
  appointmentInfo,
  bookOnlineUrl,
  config,
  provider,
  onClose,
  log,
  onViewMoreClick,
  fetchProviderAvailability,
  selectedDate,
  onTimeSlotsClick,
  purposeOptionsByProviderId,
  fetchPurposesByProviderId,
  availabilityError,
  purposesLoading
}: AvailabilityDrawerProps) => {
  const isScreenMediumOrBelow = useMediaQuery(
    `(max-width:${Theme.screen_medium})`
  );
  const {
    providerName,
    providerPhoneNumber,
    renderSlotsAsButtons,
    appointmentOptions,
    loading
  } = useAvailability({
    config,
    provider,
    appointmentInfo,
    purposeOptionsByProviderId,
    fetchPurposesByProviderId,
    availabilityError,
    purposesLoading,
    log
  });

  const handleOnClose = (_e: SyntheticEvent, reason: string) => {
    onClose(provider.id, reason);
  };
  const handlePhoneNumberClick = () => {
    log(TRACKING_EVENTS.PHONE_NUMBER_CLICK, {
      providerId: provider.id
    });
  };
  const handleTimeSlotsClick = (
    date: string | undefined,
    time: string | undefined,
    providerId: number,
    href: string,
    unformattedDate: string
  ) => {
    onTimeSlotsClick(date, time, providerId, href, unformattedDate, provider);
  };
  const showRegulatedSpacesMessage = _get(
    config,
    'direct_book.show_regulated_spaces_banner'
  );

  return (
    <SideSheet
      ariaLabelledBy="availability-drawer-title"
      dataTestId={AVAILABILITY_DRAWER.ID}
      footer={
        providerPhoneNumber ? (
          <FormattedMessage
            {...messages.phoneNumber}
            values={{
              phone: (
                <Link
                  href={`tel:${providerPhoneNumber}`}
                  onClick={handlePhoneNumberClick}
                >
                  {providerPhoneNumber}
                </Link>
              )
            }}
          />
        ) : undefined
      }
      header={
        <FormattedMessage {...messages.drawerTitle}>
          {(message) => (
            <Header id="availability-drawer-title">{message}</Header>
          )}
        </FormattedMessage>
      }
      id={AVAILABILITY_DRAWER.ID}
      onClose={handleOnClose}
      width={isScreenMediumOrBelow ? '100vw' : '580px'}
    >
      <ProviderSummary>
        <ProviderImage alt={providerName} src={getProviderImageUrl(provider)} />
        <ProviderName>{providerName}</ProviderName>
        <ProviderSpecialties>
          {getProviderSpecialties(provider)}
        </ProviderSpecialties>
      </ProviderSummary>
      <Loading loading={loading} availabilityError={availabilityError} />
      {loading === false && appointmentOptions && (
        <ProviderAvailability
          appointmentInfo={appointmentOptions.initialAppointmentInfo}
          locations={provider.locations}
          loggingFunction={log}
          maxAvailabilitiesToDisplay={MAX_AVAILABILITIES_TO_DISPLAY}
          provider={provider}
          purposeOptionsByRelationship={
            appointmentOptions.purposeOptionsByRelationship
          }
          selectedDate={selectedDate}
          services={{
            formatAvailability: (slots: Slot[]) =>
              formatAvailabilityForTimeTiles(slots, bookOnlineUrl, false),
            fetchAvailability: fetchProviderAvailability
          }}
          shouldRenderSlotsAsButtons={renderSlotsAsButtons}
          showRegulatedSpacesMessage={showRegulatedSpacesMessage}
          tileSize={AvailabilityTileSize.SMALL}
          onTimeSlotsClick={(
            date: string | undefined,
            time: string | undefined,
            providerId: number,
            href: string,
            unformattedDate: string
          ) =>
            handleTimeSlotsClick(date, time, providerId, href, unformattedDate)
          }
          bookOnlineUrl={bookOnlineUrl}
          onViewMoreClick={onViewMoreClick}
        />
      )}
    </SideSheet>
  );
};

AvailabilityDrawer.displayName = 'AvailabilityDrawer';
